import logo from './logo.svg';
import './App.css';
import Home from './pages/Home/Home';
import LogoName from './assets/logoName.png'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={LogoName} width="70%" alt="logo" />
      </header>
      <Home />
    </div>
  );
}

export default App;
