import React from 'react';
import './LandingPage.css'; // Assuming you have a CSS file for styling
import Logo from '../../assets/logo.png';
import Booking from '../../assets/booking.png';
import { InlineWidget } from 'react-calendly';

const LandingPage = () => {
    return (
        <div className="landing-page">

            <section className="logo">
                <img src={Logo} className="App-logo" alt="logo" />
                <img src={Booking} className="App-logo" alt="Booking" />
            </section>
            
            <section className="hero">
                <h1>Revolutionize Your Customer Interaction with AI-Powered Assistants</h1>
                <p>Smart Assistants for Your Business – Answering Queries and Scheduling Appointments Like Never Before</p>
                <span>Learn More</span>
                <div style={{fontSize:"3em"}}>⬇️ ⬇️ ⬇️ ⬇️ </div>
            </section>
            <section 
                style={{
                    background:"black"
                }}
                className="hero"
            >
                <h1>TRY IT FOR FREE FOR TWO WEEKS</h1>
            </section>

            <section className="about-us">
                <h2>About Us</h2>
                <p
                    style={{
                        maxWidth: "800px",
                        margin: "auto"
                    }}
                >At JoeBots, we create intelligent chatbot assistants tailored to enhance your business's customer service experience. Our bots are designed to handle inquiries, provide information, and schedule appointments seamlessly, making your customer interactions more efficient and engaging.</p>
                <p style={{fontSize: '1.2em'}}>We are base in Chicago, USA</p>
            </section>

            <section className="services">
                <h2>Our Services</h2>
                <div className="service">
                    <h3>Customer Query Handling</h3>
                </div>
                <div className="service">
                    <h3>Automated Appointment Scheduling</h3>
                </div>
                <div className="service">
                    <h3>Multiple language support</h3>
                </div>
                <div className="service">
                    <h3>24/7 Customer Support</h3>
                </div>
            </section>

            <section className="cta">
                <h2>Get Started with Your AI Assistant Today</h2>
                <p>schedule a consultation.</p>
                <InlineWidget url="https://calendly.com/pineapple-tech-solutions/30min" styles={{ minWidth: '320px', height: '700px' }} />

            </section>

        </div>
    );
};

export default LandingPage;
